



















































import { Note } from "@/models/entities/note.interface";
import format from "date-fns/format";
import { Component, PropSync, Vue } from "vue-property-decorator";

@Component
export default class CNotes extends Vue {
  @PropSync("notes", { required: true, type: Array })
  private _notes: Note[];

  private getTime(time: string): string {
    return format(new Date(time), "HH:mm");
  }

  //   private onTrashClick(item: Note): void {
  //     this._notes = this._notes.filter((el) => el.noteId !== item.noteId);
  //   }
}
